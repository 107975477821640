import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {Link} from 'gatsby'
import Head from '../components/head'
import Layout from '../components/layout'
import indexStyles from './index.module.css'

export default function Proyectos() {
    const data = useStaticQuery(graphql`
    query {
        allContentfulProject(sort: {fields: order}) {
            edges {
                node {
                    title
                    description
                    slug
                    thumbnail {
                        title
                        fixed(height: 450, width: 600, quality: 80, resizingBehavior: THUMB) {
                            src
                        }
                    }
                }
            }
        }
    }
    `)

    const posts = data.allContentfulProject.edges.map(edge => {
        return (
            <div className='col col-md-6 col-lg-4' key={edge.node.slug}>
                <figure style={{borderStyle: 'solid', borderWidth: '1px', borderColor: '#E6ECF8'}} className={indexStyles.projectsThumbnailContainer}>
                    <img src={edge.node.thumbnail.fixed.src} alt={edge.node.thumbnail.title} style={{maxWidth:'100%'}} />
                    <div className={indexStyles.projectsOverlay}>
                    <div>
                        {edge.node.description}
                    </div>
                    <Link className={'link-button'} to={`/proyectos/${edge.node.slug}`}>
                        Ver Detalles
                    </Link>
                    </div>
                </figure>
                <div style={{fontSize: '1.1rem', marginTop: '1rem', color: '#1678FF', cursor: 'pointer', textUnderlineOffset: '1px', textAlign: 'center'}}>
                    <Link to={`/proyectos/${edge.node.slug}`}>
                        <span style={{color: '#1678FF', textDecoration: 'underline'}}>
                            {edge.node.title}
                        </span>
                    </Link>
                </div>
            </div>
        )
    })

    return (
        <Layout>
            <Head title='Proyectos' />
            <h1 className='page-title'>
                Mis proyectos
            </h1>
            <div style={{marginBottom: '6em'}}>
                <div className='container'>
                    <div className={`columns ${indexStyles.projectsColumns}`}>
                        {posts}
                    </div>
                </div>
            </div>
        </Layout>
    )
}
